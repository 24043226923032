import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ichwan/Documents/repos/new-personal-site/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const Card = makeShortcode("Card");
const Img = makeShortcode("Img");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Posts`}</h1>
    <p>{`This section is still under construction 🚧`}</p>
    <SourceList filter="posts" mdxType="SourceList">
  {posts => <Flex sx={{
        flexWrap: 'wrap',
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {posts.filter(edge => !edge.node.frontmatter.isPrivate).reduce((routes, route) => {
          return route.node.frontmatter.pinned ? [route, ...routes] : [...routes, route];
        }, []).map((edge, index) => {
          const {
            featuredImageUrlSharp,
            frontmatter: {
              title,
              featuredImage,
              tags,
              date,
              pinned
            },
            excerpt,
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            maxWidth: ['100%', '100%', '50%', '50%'],
            width: ['100%', '100%', '50%', '50%']
          }} mdxType="Box">
              <Link href={slug} sx={{
              textDecoration: 'none',
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              m: theme => `0px ${theme.space[2]}px`,
              minHeight: '1px'
            }} mdxType="Link">
                <Card sx={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                minHeight: '1px'
              }} mdxType="Card">
                  {featuredImage && featuredImage.childImageSharp && <Box sx={{
                  minHeight: '1px'
                }} mdxType="Box">
                      <Img fluid={featuredImage.childImageSharp.fluid} alt={featuredImage.childImageSharp.fluid.originalName} mdxType="Img" />
                    </Box>}
                {featuredImageUrlSharp && featuredImageUrlSharp.childImageSharp && <Box sx={{
                  minHeight: '1px'
                }} mdxType="Box">
                      <Img fluid={featuredImageUrlSharp.childImageSharp.fluid} alt={featuredImageUrlSharp.childImageSharp.fluid.originalName} mdxType="Img" />
                    </Box>}
                  <Box sx={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'column',
                  p: 3
                }} mdxType="Box">
                    <Heading variant="styles.h4" sx={{
                    color: pinned ? 'primary' : 'text'
                  }} mdxType="Heading">
                      {title}
                    </Heading>
                    <Text sx={{
                    mb: 1,
                    color: 'muted'
                  }} mdxType="Text">
                      {format(new Date(date), 'd-MMM-u')}
                    </Text>
                    <Text sx={{
                    mb: 1,
                    color: 'text'
                  }} mdxType="Text">{excerpt}</Text>
                  </Box>
                  <Box sx={{
                  p: 3
                }} mdxType="Box">
                    <Text mdxType="Text">View Post</Text>
                  </Box>
                </Card>
              </Link>
            </Box>;
        })}
    </Flex>}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      